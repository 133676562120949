import React, {useState} from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

import qs from 'qs';
import { Link } from "gatsby";
import { navigate } from "../util";
import { getPrevPageUrl, getNextPageUrl, buildModel, getQueryParam, getFormData } from "../util";
import { FaChevronRight, FaChevronDown, FaSync } from "react-icons/fa";
import CurrencyInput from 'react-currency-input';
import axios from "axios";

function ProfilePage(props) {
  const [state, setState] = useState(() => {
    let stateModel = {showNotes: false};
    var query = qs.parse(props.location.search, { ignoreQueryPrefix: true }).q;
    var model = buildModel(query);
    return {...stateModel, ...model};
  });
  const nextPage = "/results";
  const prevPage = "/questions/innovation/4";

  const handleStringInputChange = (key, value) => {
    // console.log(`setting ${key}:${value}`);
    setState({...state, [key]: value });
  }

  const submitToFormFlare = async (submissionId = null) => {
    return new Promise(async (resolve, reject) => {
      console.log("Submitting to form flare...");
      setState({...state, pending: true});
      let data = await getFormData(state);
      setState({...state, error: !data.isValid, errorFields: data.errorFields});
      // const pendingUrl = 'http://account.formflare.io/0cfcf3f2-9760-410a-56c0-08d93661b6c8';
      const pendingUrl = 'https://account.formflare.io/6eab2e67-e9c4-4278-ab3e-08d9b598fb9e'
      let baseUrl = pendingUrl;
      if(submissionId) {
        baseUrl = baseUrl + "/" + submissionId;
      }
      axios({
        method: "post",
        url: baseUrl,
        data: data.formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          //handle success
          console.log(response);
          setState({...state, pending: false, success: true, submissionId: response.data.id});
          resolve();
        })
        .catch(function (response) {
          //handle error
          console.log("Submission exception");
          console.error(response);
          setState({...state, pending: false, success: false});
          reject();
        });
    });
  }

  const nextQuestion = async () => {
    submitToFormFlare(state.submissionId)
      .then(() => {
        navigate(nextPage+getQueryParam(state));
      });
  }

  let formFlareLoadingIcon = (state.pending) ? (<FaSync className="inline ml-2 spin" />) : (<></>);

  const toggleNotes = event => {
    setState({...state, showNotes: !state.showNotes });
  }

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
       <article className="text-left my-2 md:my-6 shadow-lg border border-grey-500 p-6 rounded flex flex-col question-container">
        <h2 className="heading-2">Business Profile</h2>
        <div className="flex flex-row flex-wrap md:-mx-2">
          <div className="block mb-4 animate-fade-children-up flex flex-col md:w-1/2 md:px-2">
            <label className="text-gray-700 form-label mb-2 mt-6 block" htmlFor="Name">Name <span class="text-primary">*</span></label>
            <input type="text" className="form-input" id="Name" maxLength="64" value={state.name} onChange={event => handleStringInputChange("name", event.target.value) } />
            <div className="text-gray-500 text-right">{state.name.length}/64</div>
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col md:w-1/2 md:px-2">
            <label className="text-gray-700 form-label mb-2 mt-6 block" htmlFor="businessName">Business Name <span class="text-primary">*</span></label>
            <input type="text" className="form-input" id="businessName" maxLength="64" value={state.businessName} onChange={event => handleStringInputChange("businessName", event.target.value) } />
            <div className="text-gray-500 text-right">{state.businessName.length}/64</div>
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col md:w-1/2 md:px-2">
            <label className="text-gray-700 form-label mb-2 mt-6 block" htmlFor="clientReferenceNumber">Client Reference Number</label>
            <input type="text" className="form-input" id="clientReferenceNumber" maxLength="64" value={state.clientReferenceNumber} onChange={event => handleStringInputChange("clientReferenceNumber", event.target.value) } />
            <div className="text-gray-500 text-right">{state.clientReferenceNumber.length}/64</div>
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col md:w-1/2 md:px-2">
            <label className="text-gray-700 form-label mb-2 mt-6 block" htmlFor="businessPartnerDetails">Business Partner Details <span class="text-primary">*</span></label>
            <input type="text" className="form-input" id="businessPartnerDetails" maxLength="64" value={state.businessPartnerDetails} onChange={event => handleStringInputChange("businessPartnerDetails", event.target.value) } />
            <div className="text-gray-500 text-right">{state.businessPartnerDetails.length}/64</div>
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col md:w-1/2 md:px-2">
            <label className="text-gray-700 form-label mb-2 mt-6 block" htmlFor="amoutInvestedToDate">Amount Invested To Date</label>
            <CurrencyInput className="form-input" id="amoutInvestedToDate" precision={0} prefix={"£ "} value={state.amoutInvestedToDate} onChangeEvent={(event, maskedvalue, floatvalue) => handleStringInputChange("amoutInvestedToDate", floatvalue) } />
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col md:w-1/2 md:px-2">
            <label className="text-gray-700 form-label mb-2 mt-6 block" htmlFor="amountPlannedToBeInvested">Amount Planned To Be Invested</label>
            <CurrencyInput className="form-input" id="amountPlannedToBeInvested" precision={0} prefix={"£ "} value={state.amountPlannedToBeInvested} onChangeEvent={(event, maskedvalue, floatvalue) => handleStringInputChange("amountPlannedToBeInvested", floatvalue) } />
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col md:w-1/2 md:px-2">
            <label className="text-gray-700 form-label mb-2 mt-6 block flex-1" htmlFor="ownFunding">Own Funding? <span class="text-primary">*</span></label>
            <div>
              <label className="inline-flex items-center">
                <input type="radio" className="form-radio" name="ownFunding" value="yes" checked={state.ownFunding === 'yes'} onChange={(event) => handleStringInputChange("ownFunding", event.target.value)}/>
                <span className="ml-2">Yes</span>
              </label>
              <label className="inline-flex items-center ml-6 mt-3 mb-2">
                <input type="radio" className="form-radio" name="ownFunding" value="no" checked={state.ownFunding === 'no'} onChange={(event) => handleStringInputChange("ownFunding", event.target.value)} />
                <span className="ml-2">No</span>
              </label>
            </div>
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col md:w-1/2 md:px-2">
            <label className="text-gray-700 form-label mb-2 mt-6 block flex-1" htmlFor="annualResearchDevelopmentAmount">Annual Research &amp; Development Spend In The Last Complete Financial Year</label>
            <CurrencyInput className="form-input" id="annualResearchDevelopmentAmount" precision={0} prefix={"£ "} value={state.annualResearchDevelopmentAmount} onChangeEvent={(event, maskedvalue, floatvalue) => handleStringInputChange("annualResearchDevelopmentAmount", floatvalue) } />
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col md:w-1/2 md:px-2">
            <label className="text-gray-700 form-label mb-2 mt-6 block flex-1" htmlFor="processInnovation">Number Of Product And Process Innovations In The Last Three Financial Years, And Future Plans (if applicable) <span class="text-primary">*</span></label>
            <input type="text" className="form-input" id="processInnovation" maxLength="64" value={state.processInnovation} onChange={event => handleStringInputChange("processInnovation", event.target.value) } />
            <div className="text-gray-500 text-right">{state.processInnovation.length}/64</div>
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col w-full">
            <label className="text-gray-700 form-label mb-2 mt-6 block flex-1" htmlFor="communityInvolvement">Are You Actively Involved In Any Forums Or Networks? <span class="text-primary">*</span></label>
            <textarea rows="5" className="form-input" id="communityInvolvement" maxLength="256" onChange={event => handleStringInputChange("communityInvolvement", event.target.value) }>{state.communityInvolvement}</textarea>
            <div className="text-gray-500 text-right">{state.communityInvolvement.length}/256</div>
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col w-full">
            <label className="text-gray-700 form-label mb-2 mt-6 block flex-1" htmlFor="currentEmployeeDetails">Current Employee Details <span class="text-primary">*</span></label>
            <textarea rows="5" className="form-input" id="currentEmployeeDetails" maxLength="256" onChange={event => handleStringInputChange("currentEmployeeDetails", event.target.value) }>{state.currentEmployeeDetails}</textarea>
            <div className="text-gray-500 text-right">{state.currentEmployeeDetails.length}/256</div>
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col w-full">
            <label className="text-gray-700 form-label mb-2 mt-6 block flex-1" htmlFor="employeeOpportunity">Does Taking On An Employee Present An Opportunity For Your Business? <span class="text-primary">*</span></label>
            <textarea rows="5" className="form-input" id="employeeOpportunity" maxLength="256" onChange={event => handleStringInputChange("employeeOpportunity", event.target.value) }>{state.employeeOpportunity}</textarea>
            <div className="text-gray-500 text-right">{state.employeeOpportunity.length}/256</div>
          </div>
          <div className="block mb-4 animate-fade-children-up flex flex-col w-full">
            <label className="text-gray-700 form-label mb-2 mt-6 block flex-1" htmlFor="courseInterest">Course Interest <span class="text-primary">*</span></label>
            <textarea rows="5" className="form-input" id="courseInterest" maxLength="256" onChange={event => handleStringInputChange("courseInterest", event.target.value) }>{state.courseInterest}</textarea>
            <div className="text-gray-500 text-right">{state.courseInterest.length}/256</div>
          </div>
        </div>
        <div className="md:flex text-sm text-gray-700 mt-6 text-center flex-grow items-end">
            <div className="w-full max-w-xl md:text-right">
              <a onClick={nextQuestion} className="block md:inline-block no-underline text-white bg-primary hover:bg-secondary transition-bg py-2 px-3 rounded-md">Finish! {formFlareLoadingIcon}</a>
            </div>
            <div className="w-full max-w-xl  md:text-left order-first">
              <Link className="block md:inline-block no-underline md:text-white md:bg-gray-500 md:hover:bg-gray-700 transition-bg py-2 px-3 rounded-md" to={prevPage+getQueryParam(state)}>Back</Link>
            </div>
          </div>
      </article>
      <div className="w-full text-right px-6">
        <a className="text-blue-500 inline-flex items-center" onClick={toggleNotes}>{state.showNotes ? <FaChevronDown className="inline text-xs" /> : <FaChevronRight  className="inline text-xs" /> }&nbsp;Notes</a> | <Link className="text-blue-500" to={`/results${getQueryParam(state)}`}>View Results</Link>
      </div>
      <div className={["w-full text-right px-6", state.showNotes ? "block" : "hidden"].join(" ")}>
        <textarea className="form-textarea w-full" maxLength="512" rows="5" value={state.notes} onChange={event => handleStringInputChange("notes", event.target.value) } />
        <div className="text-gray-500 text-right">{state.notes.length}/512</div>
      </div>
    </Layout>
  );
}

export default ProfilePage;